

























































































































































































































































































































































































































































































.formBOX {
  .el-form-item__content {
    width: 50%;
    .el-textarea__inner {
      resize: none;
    }
  }
  .mlf {
    .el-form-item__content {
      margin-left: 0 !important;
      display: flex;
      align-items: center;
    }
  }
  .el-input-number {
    width: 100%;
  }
  .form-itemteache {
    .el-form-item__content {
      display: flex;
      align-items: center;
      margin-left: 0;
    }
  }
  .form-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    .el-button {
      width: 10rem;
    }
  }
  .el-select {
    width: 100% !important;
  }
}

.el-icon-arrow-down {
  font-size: 12px;
  &:before {
    content: "\e6df" !important;
  }
}
